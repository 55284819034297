<template>
  <section class="about">
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
        </v-img>
    </v-flex>
    <v-container  grid-list-md text-center class="ctk-div-m">
      <v-layout align-center fill-height justify-center mb-4 mt-md-12 mb-md-12>
          <v-card flat class="ctk-submenu">
              <v-tabs grow light centered v-model="currentTab">
                <v-tab v-for="tab of tabs" :key="tab.key">
                  <a :href="'/'+tab.key">{{tab.name}}</a>
                </v-tab>
              </v-tabs>
          </v-card>
      </v-layout>
      <v-layout wrap>
        <v-flex class="text-left">
          <span v-html="contact.contact">{{contact.contact}}</span>
        </v-flex>
        <v-flex xs12 sm12 md4 lg4 pl-md-12>
          <v-img
            max-width="350"
            contain
          >
            </v-img>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 6,
    innerWidth: window.innerWidth,
    tabs: [ { key: 'contact', name: '联系方式'}, { key: 'hr', name: '企业招聘' } ],
    currentTab: 0,
    contact: {
      'contact': null
    }
  }),
  created () {
    document.querySelector('#menu_contact').classList.add('v-btn--active')
    this.getContact()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getContact () {
      this.https.get('contact').then(response => {
        if (response.code === 0) {
          this.contact = response.data
        }
      })
    }
  }
}
</script>